import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { normalizeDegrees, Speed, UnitConversions } from '../../general-utilities/numbers-util';
import { convertSpeed, SpeedUnit } from '../../general-utilities/units-util';
import { serverBaseWebSocketUrl } from '../../general-utilities/urlUtils';
import Telemetry from '../../models/Telemetry';
import { campaignRootStore } from '../../stores/CampaignRootStore';

const calculateAirSpeed = (telemetryData: Telemetry): Telemetry  => {
  const { weatherData } = campaignRootStore.weatherDataStore;

  if (!((telemetryData.ground_speed_m_s === 0 || telemetryData.ground_speed_m_s)
    && (telemetryData.attitude_euler.yaw_deg === 0 || telemetryData.attitude_euler.yaw_deg)
    && weatherData)) {
    return telemetryData;
  }

  const windSpeedKmh = weatherData?.current.wind_speed_10m;
  const windDirection = weatherData?.current.wind_direction_10m;

  const groundSpeed: Speed = {
    value: telemetryData.ground_speed_m_s,
    unit: 'm/s'
  };

  const windSpeed: Speed = {
    value: convertSpeed({
      speed: windSpeedKmh,
      fromUnit: SpeedUnit.KILOMETERS_PER_HOUR,
      toUnit: SpeedUnit.METERS_PER_SECOND
    }),
    unit: 'm/s'
  };

  const heading = normalizeDegrees(telemetryData?.heading_deg);
  const airSpeed = Math.abs(UnitConversions.gsToAs({
    groundSpeed,
    windSpeed,
    windDirectionDeg: windDirection,
    droneHeading: heading
  }));

  return {
    ...telemetryData,
    airspeed_m_s: airSpeed
  };
}

const updateWeatherDataLocation = async (telemetryData: Telemetry): Promise<void> => {
  await campaignRootStore.weatherDataStore.updateLatLong({
    latitude: telemetryData.position.latitude_deg,
    longitude: telemetryData.position.longitude_deg
  });
}

const processTelemetryData = async (telemetryData: Telemetry): Promise<void> => {
  await updateWeatherDataLocation(telemetryData);
  const updatedTelemtryData = calculateAirSpeed(telemetryData);
  campaignRootStore.telemetryStore.updateTelemetryData(updatedTelemtryData);
}

export const TelemetryController = observer(() => {
  const { socket } = campaignRootStore.telemetryStore;

  const connect = (): void => {
    if (socket) {
      return;
    }

    const socketNew = new WebSocket(`${serverBaseWebSocketUrl}/ws/telemetry`);

    campaignRootStore.telemetryStore.updateSocket(socketNew);

    socketNew.onopen = () => {
      campaignRootStore.telemetryStore.updateIsConnected(true);
      campaignRootStore.weatherDataStore.start();
    };

    socketNew.onmessage = async (event) => {
      const telemetryDataFetched: Telemetry = JSON.parse(event.data);
      await processTelemetryData(telemetryDataFetched);
    };

    socketNew.onclose = () => {
      campaignRootStore.telemetryStore.updateIsConnected(false);
      campaignRootStore.telemetryStore.updateSocket(null);
      campaignRootStore.weatherDataStore.stop();
    };

    socketNew.onerror = (error) => {
      console.error("WebSocket Error:", error);
      socketNew.close();
    };
  }

  const disconnect = (): void => {
    if (socket) {
      socket.close();
      campaignRootStore.telemetryStore.updateIsConnected(false);
      campaignRootStore.telemetryStore.updateSocket(null);
      campaignRootStore.weatherDataStore.stop();
    }
  }

  useEffect(() => {
    connect();

    return () => disconnect();
  }, []);

  return null;
});
