import * as React from 'react';

import Button from '@mui/joy/Button';

interface ContrastButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ContrastButton: React.FC<ContrastButtonProps> = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor: 'neutral.plainActiveBg',
      color: 'text.primary',
      '&:hover': {
        backgroundColor: 'neutral.plainHoverBg',
      },
    }}
  >
    {children}
  </Button>
);

export default ContrastButton;
