/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-sort-props */
import { useLocation } from 'react-router-dom';

import CampaignView from '../components/Campaign/CampaignView';
import { TelemetryController } from '../components/Campaign/TelemetryController';
import CampaignDashboardLayout from '../components/layout/CampaignDashboardLayout';
import { Campaign as CampaignType } from '../types/Campaign';
import ImageController from '../components/Campaign/ImageController';
import { RtvUiController } from '../components/Campaign/RtvUiController';

const Campaign: React.FC = () => {
  const location = useLocation();
  const campaign = location.state?.campaign as CampaignType;
  const campaignId = location.pathname.replace('/campaign/', '');

  return (
    <CampaignDashboardLayout>
      <TelemetryController />
      <ImageController />
      <RtvUiController campaignId={campaignId} />
      <CampaignView projectName='Campaigns' campaign={campaign} />{' '}
      {/** TODO replace Campaigns with Project Name */}
    </CampaignDashboardLayout>
  );
};

export default Campaign;
