/* eslint-disable react/self-closing-comp */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable import/no-absolute-path */
import { Dispatch, MouseEventHandler, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/joy';
import { observer } from 'mobx-react-lite';

import { ReactComponent as AirSpeed } from '../../assets/images/AirSpeed.svg';
import { ReactComponent as DroneSignalLevel3 } from '../../assets/images/DroneSignalLevel3.svg';
import { ReactComponent as FileMapStack } from '../../assets/images/FileMapStack.svg';
import { ReactComponent as GroundSpeed } from '../../assets/images/GroundSpeed.svg';
import { ReactComponent as PictureInPicture } from '../../assets/images/PictureInPicture.svg';
import { ReactComponent as ViewSidebar } from '../../assets/images/ViewSidebar.svg';
import { formatTimestamp } from '../../general-utilities/time-util';
import { campaignRootStore } from '../../stores/CampaignRootStore';

type ViewDetailsProps = {
  viewSidebar: boolean;
  pictureInPicture: boolean;
  fileMapStack: boolean;
  setViewSidebar: Dispatch<SetStateAction<boolean>>;
  setPictureInPicture: Dispatch<SetStateAction<boolean>>;
  setFileMapStack: Dispatch<SetStateAction<boolean>>;
};

type ViewDetailsButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  icon: ReactNode;
};

const ViewDetailsButton: React.FC<ViewDetailsButtonProps> = ({ onClick, active, icon }) => (
  <Button
    sx={{
      display: 'flex',
      width: '50px',
      height: '50px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: active ? 'neutral.outlinedActiveBg' : 'background.body',
      '&:hover': {
        backgroundColor: active ? 'neutral.outlinedActiveBg' : 'background.body'
      },
      borderRadius: '0px'
    }}
    onClick={onClick}
  >
    <Box
      sx={{
        display: 'flex',
        width: '30px',
        height: '30px',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {icon}
    </Box>
  </Button>
);

type ViewDetailsNavigationButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: ReactNode;
};

const ViewDetailsNavigationButton: React.FC<ViewDetailsNavigationButtonProps> = ({ onClick, icon }) => (
  <Button
    className='flex w-6 items-center justify-center'
    sx={{
      backgroundColor: 'background.level1',
      '&:hover': {
        backgroundColor: 'background.level1'
      },
      color: 'text.icon',
      borderRadius: '0px',
      padding: '0px'
    }}
    onClick={onClick}
  >
    {icon}
  </Button>
);

type InformationItemProps = {
  label: string;
  icon?: ReactNode;
  value: string;
  marginRight?: string;
};

const InformationItem: React.FC<InformationItemProps> = ({ label, icon = null, value }) => (
  <Box
    className='flex flex-col mt-2'
    key={label}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '7px'
    }}
  >
    <Typography
      className='text-xs'
      sx={{
        color: 'text/secondary'
      }}
    >
      {label}
    </Typography>
    <Box className='flex flex-row items-center -mt-1'>
      {icon && (
        <Box
          sx={{
            marginRight: '5px'
          }}
        >
          {icon}
        </Box>
      )}
      <Typography
        className='text-xs'
        sx={{
          color: 'text/primary'
        }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

type InformationBarProps = {};

const InformationBar: React.FC<InformationBarProps> = observer(() => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { telemetryData } = campaignRootStore.telemetryStore;

  const { i18n, t: translate } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const information = [
    {
      label: translate('campaign_view.localTime').toUpperCase(),
      value: formatTimestamp(currentTime.toString(), i18n.language, { includeTimezone: true, onlyHourAndMinute: true })
    },
    {
      label: translate('campaign_view.position').toUpperCase(),
      value: (telemetryData?.position?.latitude_deg !== undefined
        && telemetryData?.position?.longitude_deg !== undefined)
        ? `${telemetryData.position.latitude_deg.toFixed(5)} ${telemetryData.position.longitude_deg.toFixed(5)}` : ''
    },
    {
      label: translate('campaign_view.agl').toUpperCase(),
      value: telemetryData?.position?.absolute_altitude_m !== undefined
        ? `${telemetryData.position.absolute_altitude_m.toFixed(2)} (${translate('campaign_view.feetShort')})` : ''
    },
    {
      label: translate('campaign_view.airSpeed').toUpperCase(),
      icon: <AirSpeed />,
      value: telemetryData?.airspeed_m_s !== undefined
        ? `${telemetryData.airspeed_m_s.toFixed(1)} (${translate('campaign_view.metersPerSecondShort')})` : ''
    },
    {
      label: translate('campaign_view.groundSpeed').toUpperCase(),
      icon: <GroundSpeed />,
      value: telemetryData?.ground_speed_m_s !== undefined
        ? `${telemetryData.ground_speed_m_s.toFixed(1)} (${translate('campaign_view.metersPerSecondShort')})` : ''
    },
    {
      label: translate('campaign_view.droneSignal').toUpperCase(),
      icon: <DroneSignalLevel3 />,
      value: ''
    },
    {
      label: translate('campaign_view.droneBattery').toUpperCase(),
      value: telemetryData?.battery?.remaining_percent ? `${telemetryData.battery.remaining_percent}%` : ''
    }
  ];

  return (
    <Box className='flex flex-row w-full'>
      <ViewDetailsNavigationButton onClick={() => { /* TODO */ }} icon={<ChevronLeftIcon style={{ stroke: 'text.icon' }} />} />
      <Box className='flex flex-row flex-1 justify-between pl-4 pr-4'>
        {
          information.map((inf) => (
            <InformationItem key={inf.label} label={inf.label} icon={inf.value ? inf.icon : null} value={inf.value || '-'} />
          ))
        }
      </Box >
      <ViewDetailsNavigationButton onClick={() => { /* TODO */ }} icon={<ChevronRightIcon style={{ stroke: 'text.icon' }} />} />
    </Box >
  );
});

const ViewDetails: React.FC<ViewDetailsProps> = observer(({
  viewSidebar,
  pictureInPicture,
  fileMapStack,
  setViewSidebar,
  setPictureInPicture,
  setFileMapStack
}) => {
  const { t: translate } = useTranslation();

  return (
    <Box className='flex h-12'>
      <ViewDetailsButton
        active={viewSidebar}
        onClick={() => setViewSidebar((prev) => !prev)}
        icon={<ViewSidebar style={{ stroke: 'text.icon' }} />}
      />
      <ViewDetailsButton
        active={pictureInPicture}
        onClick={() => setPictureInPicture((prev) => !prev)}
        icon={<PictureInPicture style={{ stroke: 'text.icon' }} />}
      />
      <ViewDetailsButton
        active={fileMapStack}
        onClick={() => setFileMapStack((prev) => !prev)}
        icon={<FileMapStack style={{ stroke: 'text.icon' }} />}
      />
      <InformationBar />
      <Button
        className='flex items-center justify-center'
        sx={{ backgroundColor: 'neutral.solidBg', color: 'neutral.solidColor' }}
      >
        <AddIcon />
        {translate('campaign_view.waypoint')}
      </Button>
    </Box>
  );
});

export default ViewDetails;
