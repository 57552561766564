import { makeAutoObservable, reaction } from 'mobx';
import type { CampaignRootStore } from './CampaignRootStore';

class MapStore {
  currentPosition: [number, number] | null = null;

  prevPosition: [number, number] | null = null;

  positionHistory: [number, number][] = [];

  firstDataReceived = false;

  rootStore: CampaignRootStore;

  constructor(rootStore: CampaignRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.rootStore.telemetryStore.telemetryData,
      (data) => {
        if (data && data.position) {
          const { longitude_deg, latitude_deg } = data.position;
          this.setPosition(longitude_deg, latitude_deg);
        }
      },
    );
  }

  setPosition(newLng: number, newLat: number) {
    if (!Number.isNaN(newLng) && !Number.isNaN(newLat)) {
      this.prevPosition = this.currentPosition;
      this.currentPosition = [newLng, newLat];
      this.positionHistory.push(this.currentPosition);
      if (!this.firstDataReceived) {
        this.firstDataReceived = true;
      }
    } else {
      console.error('Received invalid coordinates.');
    }
  }
}

export default MapStore;
