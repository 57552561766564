/* eslint-disable no-console */
import { isEqualWith } from 'lodash';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { RtvUiState } from '../models/RtvUiState';
import type { CampaignRootStore } from './CampaignRootStore';

class RtvUiStore {
  socket: WebSocket | null = null;

  rtvUiState: Partial<RtvUiState> | null = null;

  multiSpectralImageOptions: string[] = [];

  isConnected = false;

  suppressWebSocketUpdate = false;

  lastSentState: Partial<RtvUiState> | null = null;

  rootStore: CampaignRootStore;
  

  constructor(rootStore: CampaignRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    reaction(
      () => this.rtvUiState,
      (newState) => {
        if (this.suppressWebSocketUpdate) return;
        if (this.socket && newState) {
          if (RtvUiStore.areEqualRtvUiStates(this.lastSentState, newState)) return;
          console.log('Sending new RTV UI state:', newState);
          this.socket.send(JSON.stringify(newState));
          this.lastSentState = newState;
        }
      }
    );
  }

  updateSocket(socket: WebSocket | null): void {
    this.socket = socket;
  }

  updateRtvUiState(rtvUiState: Partial<RtvUiState>): void {
    this.rtvUiState = {
      ...this.rtvUiState,
      ...rtvUiState
    };
  }

  updateRtvUiStateFromBackend(state: RtvUiState): void {
    runInAction(() => {
      if (RtvUiStore.areEqualRtvUiStates(this.rtvUiState, state)) return;
      this.suppressWebSocketUpdate = true;
      this.rtvUiState = state;
      this.suppressWebSocketUpdate = false;
    });
  }

  updateIsConnected(isConnected: boolean): void {
    this.isConnected = isConnected;
  }

  setMultiSpectralImageOptions(options: string[]): void {
    this.multiSpectralImageOptions = options;
  }

  static areEqualRtvUiStates(state1: Partial<RtvUiState> | null, state2: Partial<RtvUiState> | null): boolean {
    if (state1 === null && state2 === null) return true;

    return isEqualWith(state1, state2, (val1, val2, key) => {
      if (key === 'timestamp' || key === 'controlledById' || key === 'controlledByUsername') return true;
      return undefined
    });
  }
}

export default RtvUiStore;
