export enum ViewMode {
  MAP = 'map',
  LIVE = 'live',
}

export enum CameraSelection {
  GIMBAL = 'gimbal',
  MULTISPECTRAL = 'multi_spectral',
}

export interface RtvUiState {
  campaign: string;
  viewMode: ViewMode;
  controlledById: string;
  controlledByUsername: string;
  cameraSelection: CameraSelection;
  multispectralIndex: number;

  timestamp: string; // ISO timestamp format;
}

export type RtvWebSocketMessage =
  | { type: 'state'; state: RtvUiState }
  | { type: 'success'; message: string }
  | { type: 'error'; message: string };
