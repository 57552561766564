import React, { useEffect, useRef } from 'react';

import mapboxgl from 'mapbox-gl';
import { observer } from 'mobx-react-lite';
import 'mapbox-gl/dist/mapbox-gl.css';
import arrowIcon from '../icons/arrow.svg';
import { campaignRootStore } from '../stores/CampaignRootStore';

mapboxgl.accessToken = 'pk.eyJ1IjoianVsaWFub2VzIiwiYSI6ImNrOGNwbXVzbTA2bnMzZW95bmhvemIwbGkifQ.vHzrkhopow_IrtZm5yP5iA';

const toRad = (deg: number) => (deg * Math.PI) / 180;

function getMidpoint(a: [number, number], b: [number, number]): [number, number] {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
}

function getBearing(start: [number, number], end: [number, number]): number {
  const [lng1, lat1] = start;
  const [lng2, lat2] = end;

  const rLat1 = toRad(lat1);
  const rLat2 = toRad(lat2);
  const dLng = toRad(lng2 - lng1);

  const y = Math.sin(dLng) * Math.cos(rLat2);
  const x = Math.cos(rLat1) * Math.sin(rLat2) - Math.sin(rLat1) * Math.cos(rLat2) * Math.cos(dLng);

  let brng = Math.atan2(y, x);
  brng = (brng * 180) / Math.PI;
  return (brng + 360) % 360;
}

const MapCardView: React.FC = observer(() => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const { mapStore } = campaignRootStore;

  const cumulativePath = mapStore.positionHistory;

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [-122.4384, 37.789],
        zoom: 18,
        attributionControl: false,
      });

      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.ScaleControl(), 'top-left');

      map.current.on('load', () => {
        if (!map.current) return;

        map.current.addSource('path', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
          },
        });

        map.current.addLayer({
          id: 'path-line',
          type: 'line',
          source: 'path',
          paint: {
            'line-color': '#F5D907', // cyber yellow
            'line-width': 4,
          },
        });
      });
    }
  }, []);

  useEffect(() => {
    if (!map.current || !mapStore.currentPosition) return;

    if (!markerRef.current && mapStore.firstDataReceived) {
      const arrowElement = document.createElement('img');
      arrowElement.src = arrowIcon;
      arrowElement.style.width = '30px';
      arrowElement.style.height = '30px';

      markerRef.current = new mapboxgl.Marker({ element: arrowElement })
        .setLngLat(mapStore.currentPosition)
        .addTo(map.current);
    }

    if (markerRef.current && mapStore.prevPosition) {
      const midpoint = getMidpoint(mapStore.prevPosition, mapStore.currentPosition);
      const bearing = getBearing(mapStore.prevPosition, mapStore.currentPosition);

      const mapBearing = map.current.getBearing();
      const adjustedBearing = bearing - mapBearing;

      markerRef.current.setLngLat(midpoint);
      markerRef.current.setRotation(adjustedBearing);

      map.current.flyTo({
        center: mapStore.currentPosition,
        essential: true,
        speed: 0.5,
        curve: 1.2,
      });

      const source = map.current.getSource('path') as mapboxgl.GeoJSONSource;
      const displayedCoordinates = [...cumulativePath.slice(0, -1), midpoint];
      if (source) {
        source.setData({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: displayedCoordinates,
          },
        });
      }

      map.current.setCenter(midpoint);
    }
  }, [mapStore.currentPosition, mapStore.prevPosition, mapStore.firstDataReceived]);

  return (
    <div className='w-full h-full flex relative'>
      <style>
        {`
          .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
            display: none !important;
          }
        `}
      </style>

      <div className='w-full h-full' ref={mapContainer} />
    </div>
  );
});

export default MapCardView;
