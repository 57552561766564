// ImageStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import { serverBaseWebSocketUrl } from '../general-utilities/urlUtils';
import Image from '../models/Image';
import type { CampaignRootStore } from './CampaignRootStore';

class ImageStore {
  imageMetadata: Image | undefined = undefined;

  lastCreatedUrl: string | undefined = undefined;

  imageMetadataHistory: Image[] = [];

  // track the current index of the image displayed
  currentIndex = 0;

  liveMode = true;

  socket: WebSocket | null = null;

  rootStore: CampaignRootStore;

  constructor(rootStore: CampaignRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  // Establish the WebSocket connection to receive image metadata
  connectWebSocket() {
    this.socket = new WebSocket(`${serverBaseWebSocketUrl}/ws/drone`);
    this.socket.onmessage = (event: MessageEvent) => {
      const imageMetadataFetched = JSON.parse(event.data);
      runInAction(() => {
        this.imageMetadata = imageMetadataFetched;
        this.imageMetadataHistory.push(imageMetadataFetched);
        if (this.liveMode) {
          this.currentIndex = this.imageMetadataHistory.length - 1;
        }
      });
    };

    this.socket.onclose = () => {
      runInAction(() => {
        this.socket = null;
      });
    };
  }

  // Fetch the image and return a new object URL
  static async fetchImage(imageUrl: string): Promise<string | undefined> {
    try {
      const response = await fetch(imageUrl);
      if (response.ok) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
    return undefined;
  }

  // Action to update the image when 'playing' is true
  async updateImage() {
    if (this.liveMode && this.imageMetadata) {
      const newUrl = await ImageStore.fetchImage(this.imageMetadata.image_url);
      if (this.lastCreatedUrl) {
        URL.revokeObjectURL(this.lastCreatedUrl);
      }
      runInAction(() => {
        this.lastCreatedUrl = newUrl;
      });
    }
  }

  get currentImageMetadata(): Image | undefined {
    if (this.imageMetadataHistory.length === 0) return undefined;
    return this.imageMetadataHistory[this.currentIndex];
  }

  // Navigation actions : These are just place holders for now, but will be used in the future
  nextImage() {
    if (this.currentIndex < this.imageMetadataHistory.length - 1) {
      this.currentIndex++;
      this.liveMode = false; // user is manually navigating the images
    }
  }

  previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.liveMode = false;
    }
  }

  pause() {
    this.liveMode = false;
  }

  goToImage(imageId: string) {
    const index = this.imageMetadataHistory.findIndex((image) => image.image_id === imageId);
    if (index >= 0) {
      this.currentIndex = index;
      this.liveMode = false;
    }
  }

  goToLatest() {
    this.currentIndex = this.imageMetadataHistory.length - 1;
    this.liveMode = true;
  }

  // Optional cleanup when needed
  dispose() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export default ImageStore;
