/* eslint-disable react/jsx-sort-props */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box } from '@mui/joy';
import { observer } from 'mobx-react-lite';

import { ReactComponent as SiflyLogo } from '../../assets/images/SiflyLogo.svg';
import { ViewMode } from '../../models/RtvUiState';
import { campaignRootStore } from '../../stores/CampaignRootStore';
import ContrastButton from '../ContrastButton';

type CampaignHeaderProps = {
  projectName: string;
  campaignName: string;
};

const CampaignHeader: React.FC<CampaignHeaderProps> = observer(({
  projectName,
  campaignName
}) => {
  const { t: translate } = useTranslation();

  const { rtvUiState } = campaignRootStore.rtvUiStore;

  return (
    <Box className='flex w-full h-12 items-center justify-start gap-6 px-3'>
      <SiflyLogo />
      <Box
        sx={{
          color: 'text.secondary'
        }}
      >
        <Link to='/campaigns' className='text-inherit underline'>
          {projectName}
        </Link>
        {` / ${campaignName}`}
      </Box>
      {rtvUiState?.viewMode === ViewMode.LIVE ? (
        <>
          <ContrastButton>{translate('campaign_view.live_activity')}</ContrastButton>
          <Box className='px-4 cursor-pointer' onClick={() => campaignRootStore.rtvUiStore.updateRtvUiState({
            viewMode: ViewMode.MAP
          })}>
            {translate('campaign_view.map')}
          </Box>
        </>
      ) : (
        <>
          <Box
            className='px-4 cursor-pointer'
            onClick={() => campaignRootStore.rtvUiStore.updateRtvUiState({
              viewMode: ViewMode.LIVE
            })}
          >
            {translate('campaign_view.live_activity')}
          </Box>
          <ContrastButton>{translate('campaign_view.map')}</ContrastButton>
        </>
      )}
    </Box>
  );
});

export default CampaignHeader;
