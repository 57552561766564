import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { campaignRootStore } from '../../stores/CampaignRootStore';

const ImageController = observer(() => {
  const { imageStore } = campaignRootStore;
  useEffect(() => {
    // Initialize or open the WebSocket connection
    imageStore.connectWebSocket();

    // Cleanup on unmount
    return () => {
      imageStore.dispose();
    };
  }, []);

  return null;
});

export default ImageController;