/* eslint-disable react/jsx-sort-props */
import { useState } from 'react';

import { Box } from '@mui/joy';

import { WebRTCProvider } from '../../contexts/WebRtcContext';
import { Campaign as CampaignType } from '../../types/Campaign';
import ProducerManager from '../ProducerManager';
import CampaignActivityFeed from './CampaignActivityFeed';
import CampaignHeader from './CampaignHeader';
import ViewDetails from './ViewDetails';
import Viewport from './Viewport';

type CampaignViewProps = {
  projectName: string,
  campaign: CampaignType
}

const CampaignView: React.FC<CampaignViewProps> = ({
  projectName,
  campaign
}) => {
  const [viewSidebar, setViewSidebar] = useState<boolean>(true);
  const [pictureInPicture, setPictureInPicture] = useState<boolean>(false);
  const [fileMapStack, setFileMapStack] = useState<boolean>(false);

  return (
    <WebRTCProvider>
      <ProducerManager />
      <Box className='flex flex-col w-full h-full max-h-full'>
        <CampaignHeader
          projectName={projectName}
          campaignName={campaign?.name}
        />
        <Box className='flex flex-row w-full h-[calc(100%-48px)] max-h-[calc(100%-48px)]'>
          {viewSidebar && <CampaignActivityFeed
            setViewSidebar={setViewSidebar}
          />}
          <Box className='flex flex-col w-full max-h-full flex-1'>
            <Viewport
              displayPictureInPicture={pictureInPicture}
            />
            <ViewDetails
              viewSidebar={viewSidebar}
              pictureInPicture={pictureInPicture}
              fileMapStack={fileMapStack}
              setViewSidebar={setViewSidebar}
              setPictureInPicture={setPictureInPicture}
              setFileMapStack={setFileMapStack}
            />
          </Box>
        </Box>
      </Box>
    </WebRTCProvider>
  );
}

export default CampaignView;