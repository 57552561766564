/* eslint-disable react/jsx-sort-props */
import { useTranslation } from 'react-i18next';

import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Option, Select } from '@mui/joy';
import { observer } from 'mobx-react-lite';

import { CameraSelection } from '../../models/RtvUiState';
import { campaignRootStore } from '../../stores/CampaignRootStore';

export const CameraControls: React.FC = observer(() => {
  const { t: translate } = useTranslation();

  const { rtvUiState, multiSpectralImageOptions } = campaignRootStore.rtvUiStore;
  const { liveMode} = campaignRootStore.imageStore


  return (
    <Box className='relative flex flex-row w-full '>
      <Button
        className='whitespace-nowrap !p-4 !rounded-none flex-1'
        sx={{ backgroundColor: 'neutral.solidBg', color: 'neutral.solidColor' }}
      >
        {translate('campaign_view.camera_controls.assume_controls')}
      </Button>
      <Box className='flex-1'>
        <Select
          className='!p-4 !rounded-none'
          value={rtvUiState?.cameraSelection}
          onChange={(_, newValue) => campaignRootStore.rtvUiStore.updateRtvUiState({
            cameraSelection: newValue as CameraSelection
          })}
        >
          <Option value={CameraSelection.MULTISPECTRAL}>
            {translate('campaign_view.camera_controls.multi_spectral')}
          </Option>
          <Option value={CameraSelection.GIMBAL}>
            {translate('campaign_view.camera_controls.gimbal')}
          </Option>
        </Select>
      </Box>
      {rtvUiState?.cameraSelection === CameraSelection.MULTISPECTRAL && (
        <Select
          className='!p-4 overflow-visible !rounded-none'
          value={rtvUiState.multispectralIndex}
          onChange={(_, newValue) => {
            campaignRootStore.rtvUiStore.updateRtvUiState({
              multispectralIndex: newValue as number
            });
          }}
        >
          {multiSpectralImageOptions?.map(value => (
            <Option
              key={value}
              value={value}
            >
              {value}
            </Option>
          ))}
        </Select>
      )}
      {rtvUiState?.cameraSelection === CameraSelection.MULTISPECTRAL && (
        <Button
          className='w-[12%] !rounded-none'
          sx={{
            backgroundColor: 'background.surface',
            color: 'text.primary',
            border: '1px solid #000', // Ensures border is always applied
            borderColor: 'neutral.outlinedBorder', // Ensures border color is set properly
            '&:hover': {
              backgroundColor: 'neutral.softBg'
            }
          }}
          onClick={() => {
            if (liveMode) {
              campaignRootStore.imageStore.pause();
            } else {
              campaignRootStore.imageStore.goToLatest();
            }
          } }
            
            
        >
          {liveMode ? <PauseIcon /> : <PlayIcon />}
        </Button>
      )}
    </Box>
  );
});
