/* eslint-disable no-console */
import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { serverBaseWebSocketUrl } from '../../general-utilities/urlUtils';
import { RtvWebSocketMessage } from '../../models/RtvUiState';
import { apiClient } from '../../services/ApiService';
import { campaignRootStore } from '../../stores/CampaignRootStore';
import { MultiSpectralInfo } from '../../types/MultiSpectralInfo';

type RtvUiControllerProps = {
  campaignId: string;
}

export const RtvUiController: React.FC<RtvUiControllerProps> = observer(({ campaignId }) => {
  const { socket } = campaignRootStore.rtvUiStore;

  const connect = (): void => {

    if (socket) {
      return;
    }

    const socketNew = new WebSocket(`${serverBaseWebSocketUrl}/ws/drone/rtv/?campaign=${campaignId}`);

    campaignRootStore.rtvUiStore.updateSocket(socketNew);

    socketNew.onopen = () => {
      campaignRootStore.rtvUiStore.updateIsConnected(true);
    };

    socketNew.onmessage = async (event) => {
      const rtvUiDataFetched: RtvWebSocketMessage = JSON.parse(event.data);

      switch (rtvUiDataFetched.type) {
        case 'state':
          rtvUiDataFetched.state.campaign = campaignId;
          campaignRootStore.rtvUiStore.updateRtvUiStateFromBackend(rtvUiDataFetched.state);
          break;
        case 'error':
          console.error('Error from RTV UI WebSocket:', rtvUiDataFetched.message);
          break;
        case 'success':
          console.log('Success from RTV UI WebSocket:', rtvUiDataFetched.message);
          break;
        default:
          console.error('Unknown message type from RTV UI WebSocket:', rtvUiDataFetched);
      }

    };

    socketNew.onclose = () => {
      campaignRootStore.rtvUiStore.updateIsConnected(false);
      campaignRootStore.rtvUiStore.updateSocket(null);
    };

    socketNew.onerror = (error) => {
      console.error("WebSocket Error:", error);
      socketNew.close();
    };
  }

  const disconnect = (): void => {
    if (socket) {
      socket.close();
      campaignRootStore.rtvUiStore.updateSocket(null);
    }
  }

  useEffect(() => {
    const fetchMultiSpectralCameraTypes = async (): Promise<void> => {
      try {
        const multiSpectralInfoFetched = await apiClient.get<MultiSpectralInfo>(`/drone_commander/multispectral-index-choices/`);
        const multiSpectralChoicesArray = multiSpectralInfoFetched.data.map((item ) => item.value )
        campaignRootStore.rtvUiStore.setMultiSpectralImageOptions(multiSpectralChoicesArray);
      }
      catch (error) {
        console.error('Error fetching multi spectral information for campaign:', error);
      }
    }

    fetchMultiSpectralCameraTypes();
  }, []);

  useEffect(() => {
    connect();

    return () => disconnect();
  }, []);

  return null;
});
