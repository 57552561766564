import { ReactNode } from 'react';

import { Box } from '@mui/joy';
import { observer } from 'mobx-react-lite';

import { CameraSelection, ViewMode } from '../../models/RtvUiState';
import { campaignRootStore } from '../../stores/CampaignRootStore';
import ImageDisplay from '../ImageDisplay';
import MapCardView from '../MapDroneView';
import MapImageView from '../MapImageView';
import VideoDisplay from '../VideoDisplay';
import { CameraControls } from './CameraControls';

type ViewportProps = {
  displayPictureInPicture: boolean;
};

const Viewport: React.FC<ViewportProps> = observer(({ displayPictureInPicture }) => {
  const { rtvUiState } = campaignRootStore.rtvUiStore;

  const getRasterDisplay = (): ReactNode => {
    const dispComponent =
      rtvUiState?.cameraSelection === CameraSelection.MULTISPECTRAL
        ? <ImageDisplay />
        : <VideoDisplay />;
    return (
      <Box className='flex w-full h-full items-center justify-center' sx={{ backgroundColor: 'background.body' }}>
        {dispComponent}
      </Box>
    );
  };

  const getMapDisplay = (): ReactNode => rtvUiState?.cameraSelection === CameraSelection.MULTISPECTRAL ? <MapImageView /> : <MapCardView />;

  const bigPictureClass = 'relative flex w-full h-full items-center justify-center';
  const smallPictureClass =
    'absolute flex bottom-4 left-4 aspect-[16/9] max-w-[480px] h-[300px] items-center justify-center flex-col';

  const PictureInPicture = (): JSX.Element => (
    <Box className={`${smallPictureClass} z-1000`}>
      {rtvUiState?.viewMode === ViewMode.LIVE ? getMapDisplay() : getRasterDisplay()}
      <CameraControls />
    </Box>
  );

  return (
    <Box className='relative flex flex-1 flex-col h-[calc(100%-48px)] max-h-[calc(100%-48px)]'>
      <Box className={`${bigPictureClass}`}>
        {rtvUiState?.viewMode === ViewMode.LIVE ? getRasterDisplay() : getMapDisplay()}
      </Box>
      {displayPictureInPicture && <PictureInPicture />}
    </Box>
  );
});

export default Viewport;
