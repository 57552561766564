import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Input, Typography } from '@mui/joy';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';

import CampaignSchema from '../schema/campaignSchema';
import { CampaignService } from '../services/CampaignService';
import { organizationStore } from '../stores/OrganizationStore';
import { Campaign } from '../types/Campaign';
import GeneralModal from './GeneralModal';
import { SelectField } from './SelectField';
import TableHeader from './TableHeader';
import { TextInputField } from './TextInputField';
import CampaignCard from './Campaign/CampaignCard';

enum CampaignActiveStatus {
  Inactive = 0,
  Active = 1,
}

interface CampaignFormInput {
  name: string;
  description: string;
  status: number;
}

const CampaignList = observer(() => {
  const { t } = useTranslation();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedOrg } = organizationStore;
  const navigate = useNavigate();
  const campaignOptions = [
    { label: t('campaigns.status_options.inactive'), value: 0 },
    { label: t('campaigns.status_options.active'), value: 1 },
  ];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CampaignFormInput>({
    resolver: yupResolver(CampaignSchema(t)),
    defaultValues: {
      name: '',
      description: '',
      status: 0,
    },
  });

  const fetchCampaigns = async () => {
    setLoading(true);
    setError(false);
    try {
      const data = await CampaignService.getCampaigns();
      const org_data = data.filter((campaign) => campaign.organization === selectedOrg);
      setCampaigns(org_data);
    } catch {
      setError(true);
      toast.error(t('campaigns.loading_error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddCampaign = async (data: CampaignFormInput) => {
    if (selectedOrg === null) {
      toast.error(t('general_errors.no_organization_selected'));
      return;
    }
    try {
      const response = await CampaignService.createCampaign({
        ...data,
        organization: selectedOrg!,
      });
      setCampaigns([...campaigns, response]);
      toast.success(t('campaigns.messages.campaign_created'));
      reset();
      setIsModalOpen(false);
    } catch {
      toast.error(t('campaigns.errors.failed_to_create_campaign'));
    }
  };

  const startCampaign = async (campaignId: string) => {
    try {
      const updatedCampaign = await CampaignService.updateCampaignStatus(campaignId, CampaignActiveStatus.Active);
      setCampaigns(
        campaigns.map((campaign) =>
          campaign.id === campaignId
            ? {
                ...campaign,
                status: updatedCampaign.status,
                start_time: updatedCampaign.start_time,
                end_time: updatedCampaign.end_time,
              }
            : campaign,
        ),
      );
      toast.success(t('campaigns.campaign_status_active'));
    } catch {
      toast.error(t('campaigns.errors.campaign_status_update_failed'));
    }
  };

  const stopCampaign = async (campaignId: string) => {
    try {
      const updatedCampaign = await CampaignService.updateCampaignStatus(campaignId, CampaignActiveStatus.Inactive);
      setCampaigns(
        campaigns.map((campaign) =>
          campaign.id === campaignId
            ? {
                ...campaign,
                status: updatedCampaign.status,
                start_time: updatedCampaign.start_time,
                end_time: updatedCampaign.end_time,
              }
            : campaign,
        ),
      );
      toast.success(t('campaigns.campaign_status_inactive'));
    } catch {
      toast.error(t('campaigns.errors.campaign_status_update_failed'));
    }
  };
  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const joinCampaign = async (campaignId: string) => {
    const foundCampaign = campaigns.find((campaign) => {
      if (campaign.id === campaignId) {
        return campaign;
      }
      return null;
    });
    if (!foundCampaign) {
      console.error('Campaign not found'); // this shouldn't happen.
      return;
    }
    navigate(`/campaign/${campaignId}`, { state: { campaign: foundCampaign } });
  };

  return (
    <>
      <TableHeader title='Campaigns'>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, ml: 2 }}>
          <Input
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder='Search'
            startDecorator={<SearchIcon />}
            sx={{
              '--Input-minHeight': '2.5rem',
              maxWidth: '25rem',
              width: '100%',
            }}
            value={searchQuery}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: 1 }}>
          <Button color='neutral' onClick={handleOpenModal}>
            {t('campaigns.add_campaign')}
          </Button>
        </Box>
      </TableHeader>

      <Box sx={{ padding: '20px', fontFamily: "'Arial', sans-serif" }}>
        {loading && (
          <Box sx={{ textAlign: 'center', padding: '50px' }}>
            <CircularProgress />
            <Typography level='body-md' marginTop='15px'>
              {t('loading')}
            </Typography>
          </Box>
        )}
        {!loading && error && (
          <Box sx={{ textAlign: 'center', padding: '50px' }}>
            <Typography color='danger' level='body-md'>
              {t('campaigns.loading_failed')}
            </Typography>
          </Box>
        )}
        {!loading && !error && (
          <Box
            sx={{
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)', // 1 column on extra small screens
                sm: 'repeat(1, 1fr)', // 2 columns on small screens
                md: 'repeat(2, 1fr)', // 3 columns on medium screens
                lg: 'repeat(3, 1fr)', // 4 columns on large screens
                xl: 'repeat(4, 1fr)', // 5 columns on extra large screens
              },
              padding: '20px', // Add padding for breathing space
            }}
          >
            {filteredCampaigns.map((campaign) => (
              <CampaignCard
                key={campaign.id}
                campaign={campaign}
                startCampaign={startCampaign}
                stopCampaign={stopCampaign}
                joinCampaign={joinCampaign}
              />
            ))}
          </Box>
        )}
      </Box>

      <GeneralModal
        actions={
          <>
            <Button color='neutral' onClick={handleCloseModal} variant='plain'>
              {t('general_button_labels.cancel')}
            </Button>
            <Button color='neutral' form='campaign-form' type='submit' variant='solid'>
              {t('general_button_labels.save')}
            </Button>
          </>
        }
        content={
          <form id='campaign-form' onSubmit={handleSubmit(handleAddCampaign)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: 550 }}>
              <Box>
                <Controller
                  control={control}
                  name='name'
                  render={({ field }) => (
                    <TextInputField
                      label='Campaign Name'
                      placeholder='Enter Campaign Name'
                      {...field}
                      error={errors.name?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  control={control}
                  name='description'
                  render={({ field }) => (
                    <TextInputField
                      label='Campaign Description'
                      placeholder='Enter Description'
                      {...field}
                      error={errors.description?.message}
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography level='body-md'>{t('campaigns.status_label')}</Typography>
                <Controller
                  control={control}
                  name='status'
                  render={({ field }) => (
                    <SelectField
                      label=''
                      placeholder='Select Campaign Status'
                      {...field}
                      error={errors.status?.message}
                      options={campaignOptions}
                    />
                  )}
                />
              </Box>
            </Box>
          </form>
        }
        onClose={handleCloseModal}
        open={isModalOpen}
        title={t('campaigns.add_title')}
      />
    </>
  );
});

export default CampaignList;
