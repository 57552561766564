import { makeAutoObservable } from 'mobx';

import OpenMeteoData from '../models/Weather';
import { TelemetryService } from '../services/TelemetryService';
import type { CampaignRootStore } from './CampaignRootStore';

class WeatherDataStore {
  latitude: number | null = null;

  longitude: number | null = null;

  weatherData: OpenMeteoData | null = null;

  poller: NodeJS.Timeout | null = null;

  rootStore: CampaignRootStore;

  constructor(rootStore: CampaignRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  async updateLatLong({ latitude, longitude }: { latitude: number; longitude: number }): Promise<void> {
    this.latitude = latitude;
    this.longitude = longitude;
    await this.loadWeatherData();
  }
  

  async loadWeatherData(): Promise<void> {
    if ((this.latitude === 0 || this.latitude) && (this.longitude === 0 || this.longitude)) {
      const data = await TelemetryService.getWeatherData(this.latitude, this.longitude);
      this.weatherData = data;
    }
  }

  async start(): Promise<void> {
    if (this.poller) {
      clearInterval(this.poller);
    }
    await this.loadWeatherData();
    this.poller = setInterval(() => {
      this.loadWeatherData();
    }, 60000);
  }

  stop(): void {
    if (this.poller) {
      clearInterval(this.poller);
    }
    this.poller = null;
    this.latitude = null;
    this.longitude = null;
    this.weatherData = null;
  }
}

export default WeatherDataStore;
