/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box } from '@mui/joy';
import { observer } from 'mobx-react-lite';

import { useEffect } from 'react';

import { campaignRootStore } from '../stores/CampaignRootStore';



const ImageDisplay: React.FC = () => {

  const { imageStore } = campaignRootStore

  useEffect(() => {
    imageStore.updateImage(); // TODO: check what happens if update of imageMetadata?.image_url is faster than fetching speed and if fetch calls stack up in the event queue
  }, [ imageStore.imageMetadata?.image_url]);

  return (
    <Box className='w-full h-full object-contain flex items-center justify-center'>
      <img
        src={imageStore.lastCreatedUrl}
        alt='Raster image from server'
        style={{
          width: 'auto',
          maxHeight: '100%',
        }}
      />
    </Box>
  );
};

export default observer(ImageDisplay);
