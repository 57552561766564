import React, { useEffect, useRef } from 'react';

import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { observer } from 'mobx-react-lite';
import 'mapbox-gl/dist/mapbox-gl.css';
import arrowIcon from '../icons/arrow.svg';
import { campaignRootStore } from '../stores/CampaignRootStore';

mapboxgl.accessToken = 'pk.eyJ1IjoianVsaWFub2VzIiwiYSI6ImNrOGNwbXVzbTA2bnMzZW95bmhvemIwbGkifQ.vHzrkhopow_IrtZm5yP5iA';

const MapImageView: React.FC = observer(() => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);

  const { imageStore } = campaignRootStore;
  console.log('imageStore images length', imageStore.imageMetadataHistory.length);
  // Get the current image's position
  const currentImage = imageStore.currentImageMetadata;
  const currentPosition: LngLatLike | null = currentImage
    ? [currentImage.metadata.position.longitude_deg, currentImage.metadata.position.latitude_deg]
    : null;

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: currentPosition || [-122.4384, 37.789],
        zoom: 18,
        attributionControl: false,
      });

      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.current.addControl(new mapboxgl.ScaleControl(), 'top-left');
    }
  }, [currentPosition]);

  // Update markers whenever the image history changes
  useEffect(() => {
    if (!map.current) return;

    // Remove existing markers
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];

    imageStore.imageMetadataHistory.forEach((img) => {
      const pos: [number, number] = [img.metadata.position.longitude_deg, img.metadata.position.latitude_deg];
      const imageType = img.metadata.agricam_index_type; // e.g. "NDVI", "NDRE", etc.

      // Create a simple circular marker with text overlay
      const el = document.createElement('div');
      el.style.backgroundColor = '#F5D907'; // cyber yellow
      el.style.width = '30px';
      el.style.height = '30px';
      el.style.borderRadius = '50%';
      el.style.display = 'flex';
      el.style.alignItems = 'center';
      el.style.justifyContent = 'center';
      el.style.color = 'black';
      el.style.fontSize = '10px';
      el.style.fontWeight = 'bold';
      el.innerText = imageType;

      const marker = new mapboxgl.Marker({ element: el }).setLngLat(pos).addTo(map.current!);

      markersRef.current.push(marker);
    });
  }, [imageStore.imageMetadataHistory.length]);

  // Fly to the current image position when it changes
  useEffect(() => {
    if (!map.current || !currentPosition) return;
    map.current.flyTo({
      center: currentPosition,
      essential: true,
      speed: 0.5,
      curve: 1.2,
    });
  }, [currentPosition]);

  return (
    <div className='w-full h-full flex relative'>
      <style>
        {`
          .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
            display: none !important;
          }
        `}
      </style>
      <div className='w-full h-full' ref={mapContainer} />
    </div>
  );
});

export default MapImageView;
