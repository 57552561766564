/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import { makeAutoObservable } from 'mobx';

import Telemetry from '../models/Telemetry';
import type { CampaignRootStore } from './CampaignRootStore';

class TelemetryStore {
  socket: WebSocket | null = null;

  telemetryData: Telemetry | null = null;

  isConnected = false;

  rootStore: CampaignRootStore;

  constructor(rootStore: CampaignRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  updateSocket(socket: WebSocket | null): void {
    this.socket = socket;
  }

  updateTelemetryData(telemetryData: Telemetry): void {
    this.telemetryData = telemetryData;
  }

  updateIsConnected(isConnected: boolean): void {
    this.isConnected = isConnected;
  }
}

export default TelemetryStore;
