import { makeAutoObservable } from 'mobx';

import ImageStore from './ImageStore';
import MapStore from './MapStore';
import RtvUiStore from './RtvUiStore';
import TelemetryStore from './TelemetryStore';
import WeatherDataStore from './WeatherDataStore';

export type TelemetrySource = 'drone' | 'multispectral';

class CampaignRootStore {
  telemetryStore: TelemetryStore;

  weatherDataStore: WeatherDataStore;

  mapStore: MapStore;

  imageStore: ImageStore;

  rtvUiStore: RtvUiStore;

  constructor() {
    makeAutoObservable(this);
    this.telemetryStore = new TelemetryStore(this);
    this.weatherDataStore = new WeatherDataStore(this);
    this.mapStore = new MapStore(this);
    this.imageStore = new ImageStore(this);
    this.rtvUiStore = new RtvUiStore(this);
  }
}
const campaignRootStore = new CampaignRootStore();
export { CampaignRootStore, campaignRootStore };
